import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImageComponent from '@/components/UI/image';
import CustomLink from '@/components/UI/link';

import logo from '@/assets/images/white_logo.svg';

import style from './footer.module.scss';

const Footer = () => {
  const [year] = useState(new Date().getFullYear());
  const { t } = useTranslation();

  return (
    <footer className={style.footer}>
      <div className={style.wrapper}>
        <div className={style.footer_top}>
          <ImageComponent image={logo} alt='logo' />
          <p>{t('footer.question')}</p>
          <CustomLink
            href='/registration'
            target={false}
            style={style.button}
            text='footer.button'
          />
        </div>
        <div className={style.footer_list_rules}>
          <span className={style.footer_list_rules__item}>
            <a href={t('footer.pPolicyLink')} target='_blank'>
              {t('footer.privacyPolicy')}
            </a>
          </span>
          <span className={style.footer_list_rules__item}>
            <a href='/terms_of_use_uk.pdf' target='_blank'>
              {t('footer.termsOfUse')}
            </a>
          </span>
          <span className={style.footer_list_rules__item}>
            <a href='mailto:support@eatery.club'>support@eatery.club</a>
          </span>
        </div>
        <div className={style.footer_bottom}>
          <span
            className={style.footer_bottom__copyRight}
          >{`Copyright © ${year} by Eatery Lite. All Rights Reserved`}</span>
          <span className={style.footer_bottom_made}>
            Made with ❤️ by{' '}
            <CustomLink
              href='https://www.eatery.club/'
              target={true}
              style={''}
              text={' Eatery Club'}
            />
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
